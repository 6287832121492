import { useEffect, useState } from "react";
import Experience from "./components/Experience";
import LoadingDiv from "./components/Loading/LoadingDiv";

function App() {
  const [loadingComplete, setLoadingComplete] = useState(false);
  useEffect(() => {
    console.log(
      "%cMade by SOWEME - GD",
      "background: black; color: white;font-size:20px;margin-top:20px;margin-bottom:20px;border:4px solid #bd20ff;padding:7px 20px"
    );
  }, []);
  return (
    <>
      {!loadingComplete && (
        <LoadingDiv setLoadingComplete={setLoadingComplete} />
      )}

      <div
        style={{
          width: "100%",
          height: "100vh",
          // background: "linear-gradient(45deg, #2196F3, #E91E63)",
          // background: "#4a4a4a",
          background: "#FFFFFF",
        }}
      >
        <Experience />
      </div>
    </>
  );
}

export default App;
